import React from "react"
import { RecoilRoot } from "recoil"
import { UseWalletProvider } from "use-wallet"

import Auth from "@aws-amplify/auth"

import { AmplifyProvider } from "@aws-amplify/ui-react"

import "@aws-amplify/ui-react/styles.css"
import Layout from "./src/components/Layout/Layout"

Auth.configure({
  region: process.env.GATSBY_AWS_REGION,
  userPoolWebClientId: process.env.GATSBY_USER_POOL_ID,
  userPoolId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID,
})

const theme = {
  name: "my-theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: "#ffb7b1" },
          20: { value: "#ff8a7f" },
          40: { value: "#ff5b4c" },
          60: { value: "#ff2e1a" },
          80: { value: "#ff2e1a" },
          100: { value: "#210000" },
        },
      },
    },
  },
}

export const wrapRootElement = ({ element }) => {
  const chainId = parseInt(process.env.GATSBY_ETHERNET_CHAIN_ID)
  return (
    <UseWalletProvider chainId={chainId}>
      <AmplifyProvider theme={theme}>
        <RecoilRoot>{element}</RecoilRoot>
      </AmplifyProvider>
    </UseWalletProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
