/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import AuthenticatorModal from "../AuthenticatorModal/Authenticator"
import Modal from "../Modal/Modal"
import { useRecoilState } from "recoil"
import { AuthState, isAuthenticatedState } from "../../state/user"
import { Auth } from "aws-amplify"
import { Helmet } from "react-helmet"

import favicon from "../../images/favicon.ico"

import * as styles from "./Layout.module.scss"

const Layout = ({ children }) => {
  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)

  useEffect(() => {
    const syncAuthState = async () => {
      try {
        const res = await Auth.currentAuthenticatedUser()
        setAuthState(AuthState.authenticated)
        return res
      } catch {
        setAuthState(AuthState.unauthenticated)
        return false
      }
    }

    syncAuthState()
  }, [])

  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            href: `${favicon}`,
          },
        ]}
      />

      <div className={styles.layout}>
        <Header />
        <main>{children}</main>
      </div>

      <Footer />

      {authState === AuthState.signingUp ||
      authState === AuthState.signingIn ? (
        <Modal closeModal={() => setAuthState(AuthState.unauthenticated)}>
          <AuthenticatorModal />
        </Modal>
      ) : null}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
