import React from "react"
import FooterColumn from "../FooterColumn/FooterColumn"
import {
  TiSocialFacebookCircular,
  TiSocialTwitterCircular,
  TiSocialGooglePlusCircular,
  TiSocialPinterestCircular,
  TiSocialInstagramCircular,
  TiSocialLinkedinCircular,
  TiSocialYoutubeCircular,
} from "react-icons/ti"
import { FaWeibo } from "react-icons/fa"
import { RiTumblrFill } from "react-icons/ri"

import * as styles from "./Footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <FooterColumn
          title="Collect"
          items={[
            {
              name: "Register to Bid",
              url: "/",
            },
            {
              name: "How to Register",
              url: "https://www.artnet.com/auctions/how-to-register",
            },
            {
              name: "Buyer Protection",
              url: "https://www.artnet.com/auctions/buyer-protection",
            },
          ]}
        />

        <FooterColumn
          title="Sell"
          items={[
            {
              name: "Sell With Us",
              url: "https://www.artnet.com/auctions/sell/",
            },
            {
              name: "The Team",
              url: "https://www.artnet.com/auctions/meet-the-specialists",
            },
          ]}
        />

        <FooterColumn
          title="Search"
          items={[
            {
              name: "Advanced Search",
              url: "https://www.artnet.com/auctions/search/",
            },
            {
              name: "Search by Artist",
              url: "https://www.artnet.com/auctions/artists/",
            },
          ]}
        />
        <FooterColumn
          title="Policies"
          items={[
            {
              name: "Shipping and Payment",
              url: "https://www.artnet.com/auctions/shipping-and-payment-policy",
            },
            {
              name: "Privacy",
              url: "http://www.artnet.com/about/privacy-formal.asp",
            },
            {
              name: "Terms",
              url: "https://www.artnet.com/auctions/terms-of-use",
            },
            {
              name: "Cookies",
              url: "http://www.artnet.com/about/privacy-formal.asp#Cookies",
            },
          ]}
        />

        <FooterColumn
          title="About"
          items={[
            {
              name: "About artnet Auctions",
              url: "https://www.artnet.com/auctions/about-artnet-auctions",
            },
            {
              name: "FAQ",
              url: "/faq/",
            },
            {
              name: "Glossary",
              url: "https://www.artnet.com/auctions/glossary",
            },
            {
              name: "Site Map",
              url: "https://www.artnet.com/auctions/sitemap",
            },
          ]}
        />

        <FooterColumn
          title="Services"
          items={[
            {
              name: "Contact Us",
              url: "https://www.artnet.com/auctions/contact-us",
            },
            {
              name: "Help",
              url: "https://www.artnet.com/auctions/help",
            },
            {
              name: "Jobs",
              url: "https://www.artnet.com/jobs/",
            },
          ]}
        />
      </div>

      <ul className={styles.footerSocialLinks}>
        <li>
          <a
            href="https://www.facebook.com/artnet"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialFacebookCircular fontSize="25px" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/#!/artnet"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialTwitterCircular fontSize="25px" />
          </a>
        </li>
        {/* <li>
          <a
            href="https://twitter.com/#!/artnet"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialGooglePlusCircular fontSize="25px" />
          </a>
        </li> */}
        <li>
          <a
            href="https://www.pinterest.com/artnet/"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialPinterestCircular fontSize="25px" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/artnet/"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialInstagramCircular fontSize="25px" />
          </a>
        </li>
        <li>
          <a href="https://artnet.tumblr.com/" target="_blank" rel="noreferrer">
            <RiTumblrFill fontSize="25px" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/artnet/"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialLinkedinCircular fontSize="25px" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/c/artnet"
            target="_blank"
            rel="noreferrer"
          >
            <TiSocialYoutubeCircular fontSize="25px" />
          </a>
        </li>
        <li>
          <a
            href="https://www.weibo.com/artnet"
            target="_blank"
            rel="noreferrer"
          >
            <FaWeibo fontSize="25px" />
          </a>
        </li>
      </ul>

      {/*  */}
      <div className={styles.footerAllRights}>
        ©2021 Artnet Worldwide Corporation. All Rights Reserved.
      </div>
    </footer>
  )
}

export default Footer
