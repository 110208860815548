import React, { useState } from "react"

import * as styles from "./FooterColumn.module.scss"

interface IItem {
  name: string
  url: string
}

interface IFooterColumn {
  title: string
  items: [IItem]
}

const FooterColumn = ({ title, items }: IFooterColumn) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.footerColumn}>
      <div>
        <h3 onClick={() => setOpen(!open)}>{title}</h3>
        <ul className={styles.hiddenUptoDesktopXl}>
          {items.map((item, i) => (
            <li key={i}>
              <a href={item.url}>{item.name}</a>
            </li>
          ))}
        </ul>
        {open && (
          <ul className={styles.hiddenFromDesktopXl}>
            {items.map((item, i) => (
              <li key={i}>
                <a href={item.url}>{item.name}</a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FooterColumn
