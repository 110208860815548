import React, { useState } from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import NavBar from "../NavBar/NavBar"

import logo from "../../images/logo.png"
import hamburgerIcon from "../../images/hamburger.png"
import closeIcon from "../../images/closeLight.png"

import * as styles from "./Header.module.scss"
import Menu from "../Menu/Menu"

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <header>
      <div className={styles.headerTopNav}>
        <button
          onClick={() => setShowMenu(!showMenu)}
          className={styles.headerMenuToggle}
        >
          <img
            src={showMenu ? closeIcon : hamburgerIcon}
            alt="hamburger menu"
          />
        </button>

        <ul className={styles.headerTopNavLinks}>
          <li>
            <a href="https://www.artnet.com/">artnet</a>
          </li>
          <li>
            <a href="https://news.artnet.com/">artnet News</a>
          </li>
        </ul>
      </div>
      <div className={styles.headerInner}>
        <Link to="/">
          <img src={logo} alt="ArtNet" className={styles.logo} />
        </Link>
      </div>

      <NavBar />

      {showMenu && <Menu />}
    </header>
  )
}

export default Header
