import React, { useEffect, useState } from "react"
import { Auth } from "aws-amplify"
import { graphql, Link, useStaticQuery } from "gatsby"

import * as styles from "./NavBar.module.scss"
import { useRecoilState } from "recoil"
import { AuthState, isAuthenticatedState, userState } from "../../state/user"

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)

  const [user, setUser] = useRecoilState(userState)

  useEffect(() => {
    auth()
  }, [authState])

  const auth = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser()
      setUser(res.attributes)
      return res
    } catch {
      setUser(null)
      return false
    }
  }

  const signOut = async () => {
    try {
      await Auth.signOut()
      setAuthState(AuthState.unauthenticated)
    } catch {
      return false
    }
  }

  return (
    <div className={styles.navbar}>
      <ul className={styles.navbarList}>
        <li>
          <Link to={`/sale/${data.allContentfulSale.edges[0].node.slug}`}>
            {data.allContentfulSale.edges[0].node.name}
          </Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/terms-of-use">Terms of Use</Link>
        </li>
      </ul>
      <ul className={styles.navbarList}>
        {user ? (
          <>
            <li>
              {user?.given_name} {user.family_name}
            </li>
            <li>
              <button
                onClick={() => {
                  signOut()
                }}
              >
                Sign out
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button onClick={() => setAuthState(AuthState.signingIn)}>
                Log In
              </button>
            </li>
            <li>
              <button onClick={() => setAuthState(AuthState.signingUp)}>
                Register
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

export default NavBar
