import React from "react"
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  View,
  Image,
  Text,
  Link,
} from "@aws-amplify/ui-react"
import { I18n } from "aws-amplify"
import { useRecoilState } from "recoil"
import { AuthState, isAuthenticatedState } from "../../state/user"

//@ts-ignore
import Logo from "../../images/artnet.png"

import * as styles from "./Authenticator.module.scss"

I18n.putVocabulariesForLanguage("en", {
  "Given Name": "First Name",
  "Family Name": "Last Name",
  "Confirm Sign Up": "Confirmation code was sent to your email",
})

const AuthenticatorModal = () => {
  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)
  const initialState = authState === AuthState.signingUp ? "signUp" : "signIn"

  return (
    <Authenticator
      className={styles.auth}
      initialState={initialState}
      loginMechanisms={["email"]}
      signUpAttributes={["given_name", "family_name"]}
      components={{
        Header() {
          return (
            <View textAlign="center">
              <Image
                alt="Artnet Logo"
                src={Logo}
                maxWidth="200px"
                style={{ marginBottom: "2rem" }}
              />
            </View>
          )
        },
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator()

            return (
              <>
                <Authenticator.SignUp.FormFields label={false} />
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label={
                    <Text>
                      I agree with the{" "}
                      <Link
                        href="https://www.artnet.com/auctions/terms-of-use"
                        isExternal
                      >
                        {" "}
                        Terms & Conditions{" "}
                      </Link>{" "}
                    </Text>
                  }
                />
              </>
            )
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.acknowledgement) {
            return {
              acknowledgement: "You must agree to the Terms & Conditions",
            }
          }
        },
      }}
    >
      {({ signOut, user }) => {
        if (user) {
          setAuthState(AuthState.authenticated)
        }
        return <></>
      }}
    </Authenticator>
  )
}

export default AuthenticatorModal
