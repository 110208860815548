import React from "react"
import { Auth } from "aws-amplify"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useRecoilState } from "recoil"
import { isAuthenticatedState, userState, AuthState } from "../../state/user"
import { slugify } from "../../utils"

import * as styles from "./Menu.module.scss"

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)
  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)
  const [user, setUser] = useRecoilState(userState)

  const signOut = async () => {
    try {
      await Auth.signOut()
      setAuthState(AuthState.unauthenticated)
    } catch {
      return false
    }
  }

  return (
    <menu className={styles.menu}>
      <ul className={styles.menuList}>
        <li>
          {user ? (
            <div>
              <p>
                {user.given_name} {user.family_name}{" "}
              </p>
              ,{" "}
              <button
                onClick={() => {
                  signOut()
                }}
              >
                Sign out
              </button>
            </div>
          ) : (
            <button onClick={() => setAuthState(AuthState.signingIn)}>
              Login/Register
            </button>
          )}
        </li>
        <li>
          <Link to={`/sale/${data.allContentfulSale.edges[0].node.slug}`}>
            {data.allContentfulSale.edges[0].node.name}
          </Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/terms-of-use">Terms of Use</Link>
        </li>
      </ul>
      <ul className={styles.menuSecondList}>
        <li>
          <a href="https://www.artnet.com/">artnet</a>
        </li>
        <li>
          <a href="https://news.artnet.com/">artnet News</a>
        </li>
      </ul>
    </menu>
  )
}

export default Menu
